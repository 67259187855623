<template>
  <div class="grid-content position-relative ib-auth-login-image-wrapper">
    <!-- Logo -->
    <a
      class="logo position-absolute"
      href="https://ideabuddy.com"
      target="_blank"
    >
      <img src="@/assets/img/logos/ideaBuddy-color.svg">
    </a>
    <!-- /Logo -->

    <div
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <div class="image-text">
        <div class="signUpImageWrapper">
          <img :src="image" alt="signup image">
        </div>
        <div class="d-flex flex-column align-items-center review-wrapper">
          <p class="m-0 review-text font-outfit-medium">
            {{ $t('auth.signUp.image.text02') }}
          </p>
          <p class="m-0 subText">
            {{ $t('auth.signUp.image.subText02') }}
          </p>
        </div>

        <div class="signUpReviewImageWrapper">
          <img src="@/assets/img/auth/signup/signUpReviewImage.webp" alt="signup review image">
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AuthSignupImage',

  props: {
    image: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

.ib-auth-login-image-wrapper {
  background: #ffffff;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    top: 3%;
    left: 3%;
  }

  .image-text {
    max-width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
    line-height: 22px;

    @include media-breakpoint-up($xxxl) {
      max-width: 700px;
    }

    img {
      width: 100%;
    }

    .signUpImageWrapper {
      max-width: 470px;

      img {
        width: 80%;
      }

      @include media-breakpoint-up($xl) {
        margin-top: 160px;
        img {
          width: 100%;
        }
      }
    }

    .review-wrapper {
      row-gap: 10px;
      margin: 60px 0 100px;

      .review-text {
        font-size: 20px;
        max-width: 530px;
      }
      .subText {
         font-size: 14px;
      }
    }

    .signUpReviewImageWrapper {}
  }
}
</style>
